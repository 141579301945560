
import { defineComponent } from 'vue';

import AppNavbar from '@/components/layout/AppNavbar.vue';
import AppFooter from '@/components/layout/AppFooter.vue';
import BaseLoadingAnimation from '@/components/base/BaseLoadingAnimation.vue';

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    AppNavbar,
    AppFooter,
    BaseLoadingAnimation,
  },
});
