
import { defineComponent } from 'vue';
import CreateNewRefuelingCard from '@/components/widgets/CreateNewRefuelingCard.vue';

export default defineComponent({
  name: 'Home',
  components: {
    CreateNewRefuelingCard,
  },
  setup() {
    return {};
  },
});
