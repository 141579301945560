
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dashboard',
  components: {},

  async setup() {
    return {};
  },
});
