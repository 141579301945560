
import { defineComponent, reactive, ref, toRefs, watch } from 'vue';

import { Refueling } from '@/store/models';
import { RefuelingsModule } from '@/store';
import { SuccessMessage } from '@/util/messages';

export default defineComponent({
  props: {
    lastTotalKilometers: {
      required: false,
      type: Number,
    },
  },

  setup(props) {
    const defaultformData = {
      pricePerLitre: null,
      litres: null as any,
      totalPrice: null as any,
      date: new Date(),
      fullTank: true,
      dayKilometers: null as any,
      totalKilometers: null as any,
    };

    let formData = ref(Object.assign({}, defaultformData));
    const state = reactive({
      isLoading: false,
    });

    watch(() => formData.value.pricePerLitre, calcTotalPrice);
    watch(() => formData.value.litres, calcTotalPrice);

    watch(() => formData.value.dayKilometers, calcTotalKilometers);

    return {
      ...toRefs(state),
      formData,
      resetformData,
      checkAndSubmit,
    };

    /**
     * Calculates the total price based on the price per litre and the
     * amount of litres
     */
    function calcTotalPrice() {
      const data = formData.value;
      data.totalPrice = (data.pricePerLitre || 0) * (data.litres || 0);
      data.totalPrice = Math.round(data.totalPrice * 100) / 100;
    }

    /**
     * Calculates the total kilometers based on the day kilometers + last
     * value of total kilometers
     */
    function calcTotalKilometers() {
      const dayKilometers = formData.value.dayKilometers || 0;
      const lastTotalKilometers = props.lastTotalKilometers || 0;
      formData.value.totalKilometers = lastTotalKilometers + dayKilometers;
    }

    /**
     * Resets all the form's data fields to it's defaults
     */
    function resetformData() {
      const newformData = Object.assign({}, defaultformData);
      formData.value = newformData;
    }

    /**
     * Checks the inputs and submit the data
     */
    async function checkAndSubmit() {
      state.isLoading = true;
      Math;

      let formDataV = formData.value;
      const refueling: Refueling = {
        id: 0,
        litres: formDataV.litres || 0,
        price: formDataV.totalPrice || 0,
        pricePerLitre: formDataV.pricePerLitre || 0,
        totalKilometers: formDataV.totalKilometers || 0,
        dayKilometers: formDataV.dayKilometers || 0,
        fullTank: formDataV.fullTank,
        madeAt: formDataV.date || new Date(),
      };

      await RefuelingsModule.createRefueling(refueling);
      resetformData();
      state.isLoading = false;
      SuccessMessage.closable('Refueling was added.');
    }
  },
});
