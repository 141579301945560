
import { computed, defineComponent, PropType } from 'vue';
import { Refueling } from '@/store/models';
import { RefuelingsModule } from '@/store';

export default defineComponent({
  name: 'RefuelingsTable',
  props: {
    refuelings: {
      type: Array as PropType<Refueling[]>,
      default: [],
    },
  },
  methods: {
    deleteRefueling(idx: number) {
      const refueling = this.beautifiedRefuelings[idx];
      const confirmation = confirm(
        `Delete refueling from ${refueling.madeAt}?`
      );
      if (confirmation) {
        RefuelingsModule.deleteRefueling(refueling.id);
      }
    },
  },

  setup(props) {
    const beautifiedRefuelings = computed(() =>
      props.refuelings.map(refueling => ({
        id: refueling.id,
        madeAt: refueling.madeAt,
        litres: refueling.litres?.toFixed(2),
        pricePerLitre: refueling.pricePerLitre.toFixed(3),
        price: refueling.price.toFixed(3),
      }))
    );

    return { beautifiedRefuelings };
  },
});
