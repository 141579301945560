
import RefuelingsTable from '@/components/widgets/RefuelingsTable.vue';

import { computed, defineComponent } from 'vue';
import { RefuelingsModule } from '@/store';

export default defineComponent({
  name: 'Refuelings',
  components: {
    RefuelingsTable,
  },

  async setup() {
    const refuelings = computed(() => RefuelingsModule.refuelings);
    await loadData();

    return {
      refuelings,
    };

    function loadData() {
      return RefuelingsModule.fetchAll();
    }
  },
});
