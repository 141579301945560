
import { defineComponent, inject } from 'vue';
// eslint-disable-next-line
import { ROUTE_NAMES } from '@/router/routenames';

export default defineComponent({
  name: 'AppNavbar',
  inject: ['Auth'],
  data() {
    return {
      ROUTENAMES: ROUTE_NAMES,
    };
  },
  computed: {
    currentRoute(): string {
      return this.$route.name as string;
    },
  },
  methods: {
    userLogout() {
      this.Auth.logout({
        returnTo: window.location.origin + VUE_APP_PUBLIC_PATH,
      });
    },
  },
  setup() {
    const auth: any = inject('Auth');
    return {
      ...auth,
    };
  },
});
